.banner-footer{
	background: url('../images/banner-target.png') no-repeat;
	background-size: cover;
	background-position: center;
	height: 141px;

	@media #{$small, $mobile, $tablet}{
		height: auto;
	}

	.content{
		padding: 40px 0;

		@media #{$small, $mobile}{
			padding: 26px 0 27.6px;
		}

		@media #{$tablet}{
			padding: 40px 0 20px;
		}

		.title-footer{
			font-size: em(24);
			font-family: $bold;
			color: $white;
			text-transform: uppercase;

			@media #{$tablet}{
				font-size: em(18);
			}
		}

		.description-title-md{
			color: $text-light;
			margin-top: 10px;
			text-align: center;

			@media #{$tablet, $desktop, $hd}{
				white-space: nowrap;
			}

			@media #{$small, $mobile, $tablet}{
				margin-bottom: 40px;
				font-size: em(16);
			}

			@media #{$small, $mobile}{
				text-align: left;
				margin-top: 16px;
			}

			@media #{$tablet-extra}{
				text-align: left;
			}

			@media #{$tablet}{
				margin-left: -20px;
				font-size: em(14);
			}
		}

		.btn{
			padding: 16px 53px;

			@media #{$desktop, $hd}{
				float: right;
			}

			@media #{$small, $mobile}{
				width: 100%;
			}

			@media #{$tablet}{
				width: 100%;
				padding: 16px 0;
			}
		}
	}
}

.footer{
	background-color: $black;
	padding: 20px 0;
	text-align: center;

	.container{
		@media #{$tablet}{
			padding: 0;
		}
	}

	.nav-menu-footer{
		padding: 18px 0;

		@media #{$small, $mobile}{
			padding: 16px 0;
		}

		.menu-container-items{

			@media #{$small, $mobile}{
				display: flex;
				flex-wrap: wrap
			}

			.page_item{
				display: inline-block;
				margin-right: 24px;
				font-family: $condbold;
				text-transform: uppercase;
				font-size: em(13);
				letter-spacing: 0.9px;


				@media #{$small, $mobile}{
					width: 50%;
					margin: 0 0 32px;
					vertical-align: top;
					flex: 0 0 50%;
					padding: 0 30px;
				}



				&:after{
					content: "|";
					margin-left: 24px;
					color: $white;

					@media #{$small, $mobile}{
						display: none;
					}
				}

				&:last-child{
					margin-right: 0;

					&:after{
						content: " ";
						margin: 0;
					}

					a{
						transform: translateY(0);
					}
				}

				a{
					color: $white;

					@media #{$small, $mobile}{
						transform: translateY(30%);
						display: block;
					}
				}
			}
		}
	}

	.info-contact{
		padding: 14px 0;

		@media #{$small, $mobile}{
			padding: 0 0 14px;
		}

		.icon-phone-red{
			font-size: em(20);
			color: $icon-red;
			vertical-align: middle;
			margin-right: 5.6px;
		}

		.number{
			color: $white;
			font-size: em(16);
			line-height: 1.25;
			letter-spacing: 0.5px;
			font-family: $semibold;
		}
	}

	.right-text{
		margin-top: 13px;
		font-size: em(14);
		line-height: 1.79;
		color: $gray;
	}
}
