.menu-mobile{
	z-index: 99999;
	position: fixed;
	background-color: $text-dark;
	width: 100%;
	height: 100vh;
	padding: 23px 15px;
	right: 100%;	
	transition: 300ms all ease-in-out;

	.close-menu{
		background: url('../images/close-menu.svg') no-repeat;
		height: 18px;
		width: 18px;
		float: right;
	}

	.nav-menu-mobile{
		clear: both;
		text-align: center;

		.page_item{
			font-size: em(20);
			font-family: "Proxima Cond Semibold";
			text-transform: uppercase;
			margin-bottom: 38px;

			a{
				color: $white;
				border-bottom: 2px solid transparent;
				margin-bottom: 1px;
				text-decoration: none;
				
				&:hover{
					border-color: $red-light;
					text-decoration: none;
				}
			}

		}
	}

	&.open{
		transform: translateX(100%);
	}
}