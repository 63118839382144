.item-solucao{
	margin-bottom: 72px;
	padding-right: 120px;

	@media #{$mobile}{
		padding-right: 15px;
	}

	.item-image{
		margin-bottom: 16px;
		height: 175px;
		position: relative;

		img{
			height: 160px;
			max-width: 100%;
			position: absolute;
			bottom: 0;
		}
	}

	.title-about{
		padding-bottom: 12px;
	}

	.item-description{
		color: rgba(0, 0, 0, 0.54);
	}
}
