// Colors
$white: #ffffff;
$black: #000000;
$black-text: #222222;
$dark: #212121;
$red: #b4051a; // buttons
$red-light: #d0011b; 
$red-dark: #a20015;
$gold: #ffd700;
$silver: #c0c0c0;
$gray: #b3b3b3;
$gray-light: #f8f8f8;
$gray-light-max: #9a9a9a;
$red-dark-text: #b3051b;
$gray-transparent-text: rgba(0, 0, 0, 0.54); //rgba
$text-dark: rgba(0, 0, 0, 0.87); //rgba
$text-light: rgba(255, 255, 255, 0.70); //rgba
$bg-light: 0 0 0, 0.12;
$border-gray: 0 0 0, 0.04;
$border-dark: #010101;
$icon-red: #de041f;

// Titles
$bold: "Proxima Nova Bold";
$light: "Proxima Nova Light";
$semibold: "Proxima Nova Semibold";
$condbold: "Proxima Cond Semibold";

// Titles Style
.title-primary{
	font-size: em(45);
	line-height: 1.07;

	@media #{$small, $mobile}{
		font-size: em(34);
	}
}

.title-pages{
	font-family: $light;
	font-size: em(56);
	line-height: 0.86;
	letter-spacing: -1px;
	color: $white;
	margin-bottom: 12px;
}

.description-title{
	font-size: em(16);
	line-height: 1.56;
}

.description-title-md{
	font-size: em(20);
	line-height: 1.5;
}

// Mixins
@mixin odd(){
  &:nth-child(odd) {
    @content
  }
}
