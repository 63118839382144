.clientes{

	.title-page{
		@media #{$tablet}{
			margin-bottom: 64px;
		}
	}

	.client-logo{
		padding: 8px 0 85px;

		@media #{$tablet}{
			padding: 0 0 65px; 
		}

		@media #{$small, $mobile}{
			padding: 8px 0 12px;
		}

		div{
			align-self: baseline;
			text-align: center;

			@media #{$small, $mobile}{
				margin-bottom: 58px;
			}

			@media #{$tablet}{
				margin-bottom: 30px;
			}

			img{
				max-width: 100%;
				height: auto;
			}
		}
	}

	.description-title-md{
		display: block;
		margin-bottom: 40px;
		font-family: $semibold;
		color: $red-dark-text;
	}

	.testimonial-container{
		margin-bottom: 76px;

		@media #{$small, $mobile}{
			margin-bottom: 64px;
		}
		.credits-name, .credits-location{
			font-weight: bold;
		}
	}

	.hide{
		display: none;
	}
	.show{
		display: block;
	}
	a#btn_cliente_loadmore{
		color:$white;
		margin: 0 auto;
	}
}