.solicitar-orcamento{
	.title-page{
		margin-bottom: 32px;
	}

	.info-contact-content{
		.info-contact{
			margin-top: 35px;
			float: right;
			background-color: $gray-light;
			padding: 32px 50px 45px 52px;

			@media #{$tablet, $mobile, $small}{
				float: none;
			}

			@media #{$small, $mobile}{
				padding: 32px 16px 45px 20px;
			}

			.title-contact-box{
				color: $gray-transparent-text;
				font-size: em(24);
				line-height: 1.17;
				margin-bottom: 32px;
			}

			.contact-item{
				margin-bottom: 24px;

				&:last-child{
					margin-bottom: 0;
				}

				span{
					display: inline-block;
				}

				.icon{
					width: 20px;
					color: $red;
					vertical-align: baseline;
					margin-right: 13px;

					&.icon-phone-white, &.icon-location-white{
						font-size: em(21);
					}

					&.icon-mail-white{
						font-size: em(14);
					}
				}

				.info-contact-item{
					font-size: em(14);
					color: $text-dark;
					letter-spacing: 0.4px;
					line-height: 1.43;
					vertical-align: top;
				}
			}
		}

		.selo-container{
			width: 400px;
			float: right;
			clear: right;

			.selo{
				margin: 32px auto;
			}

			@media #{$tablet, $mobile, $small}{
				float: none;
				margin: 0 auto;
				width: 100%;
			}
		}
	}

	// formulário
	.form-contact-content{

		// styles globals
		.nf-field-label{
			margin-bottom: 8px;

			label{
				font-size: em(16);
				font-weight: 400;
				color: $text-dark;
			}
		}

		.nf-field-element{
			input, textarea, select{
				background-color: rgba(0, 0, 0, 0.04);
				border: 1px solid rgba(0, 0, 0, 0.12);
				padding: 0 15px;
				transition: all ease-in-out 250ms;

				&:focus{
					outline: 0;
					border-color: $gray-transparent-text;
				}
			}

			input, select{
				height: 40px;
			}

			input[type='checkbox']{
				height: auto;
			}
		}

		.textarea-container{
			margin-bottom: 56px;

			textarea{
				height: 75px;
				padding: 5px 15px;
			}
		}

		.list-checkbox-wrap{
			/* Reset and base for label custom style */
			[type="checkbox"]:not(:checked),
			[type="checkbox"]:checked{
				position: absolute;
				left: -99999px;
			}

			[type="checkbox"]:not(:checked) + label, 
			[type="checkbox"]:checked + label{
				position: relative;
				padding-left: 36px;
				cursor: pointer;
			}

			/* checkbox custom aspect */
			[type="checkbox"]:not(:checked) + label:before{
				content: "";
				position: absolute;
				top: -2px;  left: 0;
				background: url('../images/icons/checkbox-off.svg') no-repeat;
				background-size: contain;
				width: 24px;
				height: 24px;
				transition: all ease-in-out 200ms;
			}

			[type="checkbox"]:checked + label:before{
				content: "";
				position: absolute;
				top: -2px;  left: 0;
				background: url('../images/icons/checkbox-on.svg') no-repeat;
				background-size: contain;
				width: 24px;
				height: 24px;
				transition: all ease-in-out 200ms;
			}

			.nf-field-label{
				margin-bottom: 24px;
			}

			ul{
				display: flex;
				flex-wrap: wrap;

				li{
					flex: 0 0 50%;
					width: 50%;
					-web-box-flex: 0;

					// sobrescreve !important do ninja forms
					height: 40px !important;
					margin: 16px 0 !important;

					@media #{$small, $mobile}{
						flex: 0 0 100%;
						width: 100%;
					}

					label{
						font-size: em(14);
						letter-spacing: 0.4px;
						line-height: 1.43;
						color: rgba(0, 0, 0, 0.54);
					}
				}
			}
		}

		.list-select-wrap{
			/* custom style select field */

			.nf-field-element{
				/* init custom styles */

				width: 100%;
				background: url('../images/icons/arrow-dropdown.svg') no-repeat 97% 50%;
			}
			
			select{
				/* reset style */
				-webkit-appearance: none;
				border-radius: 0;
			}	
		}

		.submit-container{
			margin-bottom: 32px;

			.submit-wrap{
				.nf-field-element{
					input{
						@extend .btn;
						font-family: $bold;
						padding: 16px 59px 16px 62px;
						background-color: $red;
						height: auto;
						
						@media #{$small, $mobile}{
							width: 100%;
						}
					}

				}
			}
		}

		.checkbox-wrap{
			input[type="checkbox"]{
				cursor: pointer;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				outline: 0;
				padding: 0;
				width: 18px;
				height: 18px;
				border: 2px solid #ccc;
				background-color: $white;
				transition: all ease-in-out 250ms;
			}
			
			input[type="checkbox"]:checked{
				background: url('../images/icons/checkmark.svg') center/80% no-repeat;
			}

			.nf-field-label{
			
				/* position: absolute; */
			    width: auto;
			    /* margin-left: 25px; */
			    padding: 0;
			    display: inline-table;
			    /* float: left !important; */


				label{
					font-size: em(14);
					color: $black-text;
				}

				@media #{$small, $mobile}{
					width: calc(100% - 35px);
				}
			}

			.nf-field-description{
				@media #{$small, $mobile}{
					margin-right: 0;
				}

				.description{
					color: $gray-light-max;
					font-size: em(14);
					letter-spacing: 0.4px;
					white-space: nowrap;
					margin-left: 30px;

					@media #{$small, $mobile}{
						white-space: normal;
						display: block;
						
					}
				}
			}
		}
	}
}
.nf-field-description{
	margin-top: 40px!important;
    margin-left: 0px !important;
    float: left !important;
    position: absolute !important;
}
label.nf-checked-label{
	float: left !important;
}
.field-wrap{
	display: block !important;
}