.icon-quote{
	color: $red;
	font-size: em(26);
	margin-bottom: 16px;
	display: block;
}

.testimonial{
	font-family: "Proxima Nova Italic";
	font-size: em(20);
	line-height: 1.4;
	font-weight: 300;
	color: $text-dark;
	font-style: italic;
	margin-bottom: 8px;

	&.testimonial-home{
		
		text-align: center;
	}
}

.credits{
	font-size: em(14);
	line-height: 1.57;
	text-align: center;
	color: $gray-transparent-text;
}

.credits-name:after{
	content: "/";
	margin: 0 5px;
}