.flag-container{
	padding: 58px 0;

	div{
		align-self: baseline;

		@media #{$small, $mobile, $tablet}{
			margin-bottom: 58px;
		}

		img{
			max-width: 100%;
			height: auto;
		}
	}

	&.flag-container-desktop{
		@media #{$small, $mobile}{
			display: none;
		}
	}

	&.flag-container-mobile{
		@media #{$small, $mobile}{
			display: flex !important;
		}
	}
}