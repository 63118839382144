.btn{
	border-radius: 2px;
	color: $white;
	letter-spacing: 1.5px;
	font-size: em(14);
	text-transform: uppercase;
	transition: all 250ms ease-in-out;

	&:hover{
		color: $white;
		text-decoration: none;
		opacity: 0.7;
	}

	&:focus{
		box-shadow: 0 0 0 0 !important;
	}
} 

// Tamanhos
.btn-small{
	padding: 16px 32px;
}

.btn-medium{
	padding: 16px 40px;
}

.btn-large{
	padding: 16px 83px;
}


.btn-primary{
	background-color: $red;
	border: 0;
	font-family: $bold;

	&:hover{
		background-color: $red;
	}

	@extend .btn-large;
}

.btn-primary-small{
	background-color: $red;
	border: 0;
	padding: 16px 36px 16px 33px;
	font-family: $semibold;

	&:hover{
		background-color: $red;
	}
}

.btn-transparent{
	background-color: transparent;
	border: 1px solid $white;
	font-family: $bold;

	@extend .btn-medium;
}

.btn-plan{	
	@extend .btn-small;
	font-family: $semibold;
}

.btn-seemore{
	display: block;
	margin-top: 17px;
	color: $red-dark-text;
	font-size: em(14);
	letter-spacing: 0.6px;
	font-family: $semibold;

	&:hover{
		color: $red-dark-text;
		text-decoration: underline;
	}
}
