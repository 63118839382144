.tecnologia{
	.title-page{
		margin-bottom: 0;
	}

	.flag-container{
		margin-bottom: 150px;
		padding:22.5px 0;

		div{
			padding-top: 46.5px;
			padding-bottom: 46.5px;

			@media #{$small, $mobile}{
				padding: 46.5px 0 0;
				margin-bottom: 0;
			}

			img{
				max-width: 100%;
			}

			&.flag-center{
				text-align: center;
			}

			&.flag-right{
				text-align: right;

				@media #{$tablet, $mobile, $small}{
					text-align: center;
				}
			}

			@media #{$tablet, $mobile, $small}{
				text-align: center;
			}
		}
	}
}
