.content-page{
	margin: 45px 0;

	.selo{
		background-image: url('../images/selo.png');
		background-size: cover;
		height: 229px;
		width: 240px;
		margin-bottom: 10px;

		@media #{$small, $mobile, $tablet}{
			width: 209px;
			height: 199px;
			margin: 0 auto 10px;
		}
	}

	.title-page{
		font-size: em(24);
		line-height: 1.17;
		color: $red-dark-text;
		max-width: 554px;
		margin-bottom: 69px;
	}
}

.error404{
	height: 100vh;

	aside.container{
		padding: 100px 0;

		a{
			color: $red-dark-text;
		}
	}

	.footer{
		position: absolute;
		bottom: 0;
		width: 100%;

		@media #{$small, $mobile}{
			position: relative;
		}
	}
}
