body.home{
	background-color: $gray-light;

	.container{
		@media #{$mobile-extra}{
			width: 100%;
		}
	}

	.selo{
		background-image: url('../images/selo-small.png');
		background-size: cover;
		height: 150px;
		width: 157px;
		margin-bottom: 10px;

		@media #{$small, $mobile}{
			width: 111px;
			height: 106px;
		}
	}

	.header-home{
		height: 697px;

		@media #{$small, $mobile, $tablet}{
			height: auto;
			min-height: 697px;
		}

		.banner-hero{
			background: url('../images/banner-hero.jpg') no-repeat;
			background-size: cover;
			background-position: top center;
			position: absolute;
			top: 0;
			height: 697px;
			width: 100%;
			// z-index: 5;

			@media #{$small, $mobile}{
				background-position: 31%;
				height: 565px;
			}

			&:after {
				content: " ";
				display: block;
				background: url('../images/banner-hero-mask.png') no-repeat;
				background-size: contain;
				background-position: bottom left;
				height: 101px;
				width: 100%;
				position:absolute;
				bottom: 0;
				left: 0;
				z-index: 10;

				@media #{$hd}{
					background-size: cover;

				}
			}
		}

		.hero-content{
			margin-top: 28px;
			z-index: 9999;

			@media #{$small, $mobile}{
				margin-top: 0;
			}

			.title-primary{
				color: $white;
				margin: 23px 0;
				letter-spacing: -0.7px;

				@media #{$small, $mobile}{
					font-size: em(24);
					padding-right: 15px;
					margin: 12px 0;
				}
			}

			.description{
				color: $white;
				font-size: em(16);
			}

			.btn{
				margin-top: 33px;

				@media #{$small, $mobile}{
					margin-top: 24px;
				}

				&:focus{
					color: $white;
				}
			}


			.box-service-request{
				background-color: $dark;
				padding: 50px 40px 50px 39px;
				max-width: 390px;
				float: right;
				margin-top: 70px;

				@media #{$small, $mobile}{
					float: none;
					margin-top: 48px;
					padding: 38px 24px 32px;
				}

				@media #{$tablet}{
					margin-top: 30px;
					float: none;
				}

				.title-request{
					font-family: 600;
					font-size: em(24);
					text-transform: uppercase;
					color: $white;
					width: 257px;
					margin-bottom: 16px;
				}

				.description-title-md{
					color: $text-light;

					@media #{$small, $mobile}{
						font-size: em(16);
					}
				}

				.btn{
					@media #{$small, $mobile}{
						width: 100%;
						padding: 16px 0;
					}
				}
			}
		}
	}

	.strip-home{
		padding: 74px 0;

		.title-primary{
			color: $red-dark;
			text-align: center;
			display: block;
			margin-bottom: 16px;

			@media #{$small, $mobile}{
				padding: 0 20px;
			}
		}

		.description{
			width: 554px;
			text-align: center;
			color: $gray-transparent-text;
			display: block;
			margin: 0 auto;

			@media #{$small, $mobile}{
				width: auto;
				padding: 0;
			}
		}

		.description-title{
			max-width: 554px;
			margin: 0 auto;
			text-align: center;
		}
	}

	.strip-plan-options{

		.introduction-plan{
			margin-bottom: 41px;
		}

		span.icon{
			font-size: em(65);
			text-align: center;
			display: block;
			margin-bottom: 16px;

			&.icon-plan-silver{
				color: $silver;
			}

			&.icon-plan-gold{
				color: $gold;
			}
		}

		.container-plan{
			width: 354px;
			text-align: center;
			margin: 0 auto;
			border: solid 1px rgba(151, 151, 151, 0.31);
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.13);
			border-radius: 5px;
			background-color: $white;

			@media #{$tablet, $mobile, $small}{
				width: auto;
			}

			@media #{$small, $mobile}{
				margin-bottom: 48px;
			}

			.title-plan{
				font-size: em(32);
				letter-spacing: 3px;
				text-transform: uppercase;
				color: $white;
				padding: 20px 0 18px;
				font-family: $bold;
			}


			.item-plan{
				padding: 20px 46px;
				line-height: 1.56;
				font-size: em(16);
				color: $gray-transparent-text;

				@include odd()
				{
					background-color: $gray-light;
				}

				&.strong{
					font-family: $bold;
				}

				@media #{$small, $mobile}{
					padding: 20px 16px;
				}
			}

			.item-btns{
				padding: 22px 0 20px;
			}

			&.plan-silver{
				.title-plan, .btn-plan{
					background-color: $silver;
				}
			}

			&.plan-gold{
				.title-plan, .btn-plan{
					background-color: $gold;
				}
			}
		}
	}

	.strip-products{
		background-color: $white;

		@media #{$desktop}{

			.title-primary{
				text-align: center;
			}

			.description{
				text-align: center;
			}
		}


		@media #{$small, $mobile}{
			text-align: center;
		}

		.products-container{
			margin-top: 54px;

			.item-product{
				display: inline-block;
				margin-bottom: 60px;

				@media #{$small, $mobile}{
					text-align: center;
				}

				.products-img{
					height: 110px;
				}

				.product-name{
					font-size: em(16);
					line-height: 1.56;
					font-family: $semibold;
					color: $red-dark-text;
					margin-bottom: 8px;
					margin-top: 14px;
					text-transform: uppercase;
				}

				.product-description{
					font-size: em(14);
					line-height: 1.57;
					color: $text-dark;
					max-width: 260px;

					@media #{$small, $mobile}{
						margin: 0 auto;
					}
				}
			}
		}
	}

	.strip-about{
		padding: 35px 0;
		text-align: center;
		background: url('../images/banner-target.png') no-repeat;
		background-size: cover;
		background-position: center center;
		height: 576px;

		@media #{$small, $mobile}{
			height: auto;
		}

		.selo{
			margin: 0 auto;
		}

		.title-primary{
			padding: 24px 0;
			color: $white;

			@media #{$small, $mobile}{
				padding: 24px 30px;
			}
		}

		.description-title-md{
			color: $text-light;
			font-weight: $semibold;
		}

		.description-title{
			max-width: 749px;
			color: $white;
			padding: 16px 0;
			margin: 0 auto;

			@media #{$small, $mobile}{
				padding: 16px 20px;
			}
		}

		.btn{
			margin: 16px 0;

			@media #{$small, $mobile}{
				width: 100%;
			}
		}
	}

	.strip-flag-section{
		text-align: center;

		.description-title{
			padding: 17px 0;
			max-width: 554px;
		}

		&.flag-section-parters{
			background-color: $white;

			@media #{$desktop}{
			
				.title-primary{
					text-align: center;
				}

				.description{
					text-align: center;
				}
			}

			.flag-container-desktop{
				padding: 58px 0 0;

				div{
					padding: 47px 15px;
				}
			}
		}

		&.flag-section-clients{

			.description-title-md{
				margin: 39px 0 32px;
				color: $red-dark-text;
				font-weight: 600;
				display: block;

				@media #{$small, $mobile, $tablet}{
					margin-top: 0;
					padding: 0 30px;
				}
			}

			.testimonials-content{
				margin-bottom: 39px;

				@media #{$small, $mobile, $tablet}{
					margin-bottom: 0;
				}

				.testimonial-container{
					@media #{$small, $mobile, $tablet}{
						margin-bottom: 40px;
					}

					@media #{$small, $mobile}{
						padding: 0 30px;
					}
				}
			}

			.btn{
				margin: 17px auto;
			}
		}
	}

	.strip-contact-home{
		background-color: $dark;
		padding: 84px 0 67px;

		@media #{$desktop}{
			
			.title-primary{
				text-align: left;
			}

			.description{
				float: left;
				text-align: left;
			}
		}

		.title-primary{
			color: $white;

			@media #{$small, $mobile}{
				text-align: center;
			}
		}

		.description{
			color: $gray;
			margin-bottom: 65px;

			@media #{$small, $mobile}{
				text-align: center;
			}
		}

		.itens-contact{
			color: $white;

			.contact-item{
				margin-bottom: 20px;

				&:last-child{
					@media #{$mobile-extra}{
						white-space: nowrap;
					}
				}

				span, a{
					display: inline-block;
					vertical-align: top;
					color: $white;
					font-size: em(16);

					&.icon{
						margin-top: 4px;
						margin-right: 17px;

						@media #{$small, $mobile, $tablet}{
							margin-right: 5px;
						}
					}
				}

				.icon-location-white{
					font-size: em(21);
				}

				.icon-phone-white{
					font-size: em(21);
				}

				.icon-mail-white{
					font-size: em(14);
				}
			}
		}

		.selo{
			margin-top: 40px;

			@media #{$small, $mobile}{
				height: 193px;
				width: 202px;
				margin: 40px auto;
			}
		}

		.maps-location{
			.map-link-bg{
				background: url('../images/maps.png') no-repeat;
				background-position: top;
				background-size: cover;
				width: 100%;
				height: 290px;
				display: block;
				background-position: -53px;
			}
		}
	}
}
