@font-face {
  font-family: 'font-icon';
  src:  url('../fonts/font-icon.eot?25cih2');
  src:  url('../fonts/font-icon.eot?25cih2#iefix') format('embedded-opentype'),
        url('../fonts/font-icon.ttf?25cih2') format('truetype'),
        url('../fonts/font-icon.woff?25cih2') format('woff'),
        url('../fonts/font-icon.svg?25cih2#font-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'font-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-dropdown:before {
  content: "\e900";
}
.icon-breadcrumb:before {
  content: "\e901";
}
.icon-checkmark-red:before {
  content: "\e902";
}
.icon-checkmark:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-location-white:before {
  content: "\e905";
}
.icon-location:before {
  content: "\e906";
}
.icon-mail-white:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e908";
}
.icon-menu:before {
  content: "\e909";
}
.icon-phone-red:before {
  content: "\e90a";
}
.icon-phone-white:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-plan-gold:before {
  content: "\e90d";
}
.icon-plan-silver:before {
  content: "\e90e";
}
.icon-plan .path1:before {
  content: "\e90f";
  color: rgb(255, 196, 0);
}
.icon-plan .path2:before {
  content: "\e910";
  margin-left: -1.1015625em;
  color: rgb(196, 196, 196);
}
.icon-quote:before {
  content: "\e911";
}