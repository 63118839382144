.breadcrumbs{
	margin: 14px 0;
	
	li{
		display: inline-block;
		font-size: em(12);
		letter-spacing: 0.4px;
		color: $gray-light-max;
		margin-right: 8px;

		a{
			color: $black-text
		}

		&.icon-breadcrumb{
			font-size: em(8);
			color: $red;
		}

		&.page-active{
			color: $gray-light-max;
		}
	}
}