.home{ // Working on home only
	.header{
		padding: 25px 0;

		.logo img{
			@media #{$small, $mobile}{
				width: 212px;
				height: 33.6px;
			}
		}

		.btn-open-menu{
			color: $white;
			margin-top: 8px;
		}
	}
}

// Working on all pages
.header{
	padding: 14px 0;
}

.logo{
	@media #{$small, $mobile, $tablet}{
		display: inline-block;
	}

	a{
		display: block;

		img{
			@media #{$small, $mobile}{
				width: 212px;
			}
		}
	}
}

.btn-open-menu{
	vertical-align: -webkit-baseline-middle;
	display: inline-block;
	float: right;

	@media #{$desktop, $hd}{
		display: none;
	}
}

.nav-menu-primary{


	.menu-container-items{
		text-align: right;

		.page_item{ //li
			font-family: $condbold;
			display: inline-block;
			font-size: em(14);
			font-weight: 600;
			letter-spacing: 1px;
			margin-right: 30px;
			text-transform: uppercase;
			border-bottom: 2px solid transparent;
			padding: 0 1px;
			transition: border 250ms ease-in-out;

			&:last-child{
				margin-right: 0;
			}

			&:hover{
				border-color: $red-light;
			}

			&:last-child:hover{
				border-color: transparent;
			}

			a{
				text-decoration: none;
				margin-bottom: 1px;
			}

			&.current_page_item{
				border-color: $red-light;
			}
		}
	}

	&.nav-default{
		margin-top: 15px;

		a{
			color: $text-dark;
		}
	}

	&.nav-home{
		margin-top: 5px;

		a{
			color: $white;
		}
	}
}

.nav-desktop{
	@media #{$small, $mobile, $tablet}{
		display: none;
	}
}

// Hedear on pages
// Worlking on pages only
.pages{
	.btn-open-menu{
		padding: 14px 0;

		@media #{$small, $mobile}{
			padding: 0;
		}
	}

	.page-header{
		background: url('../images/banner-target.png') no-repeat;
		background-size: cover;
		background-position: center;
		height: 218px;
		position: relative;

		.content{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%);
			padding: 40px 0;

			@media #{$small, $mobile}{
				width: 100%;
				padding: 18px 0;
			}

			.title-pages{
				@media #{$small, $mobile}{
					font-size: em(48);
				}
			}

			.description-title{
				color: $text-light;
			}
		}
	}
}
