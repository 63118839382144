@font-face{
	font-family: "Proxima Nova";
	src: url('../fonts/ProximaNova-Regular.otf');

	font-style: normal;
}

@font-face{
	font-family: "Proxima Nova Bold";
	src: url('../fonts/ProximaNova-Bold.otf');

	font-style: normal;
	font-weight: 600;
}

@font-face{
	font-family: "Proxima Nova Light";
	src: url('../fonts/ProximaNova-Light.otf');

	font-style: normal;
	font-weight: 300;
}

@font-face{
	font-family: "Proxima Nova Italic";
	src: url('../fonts/ProximaNova-LightItalic.otf');

	font-style: italic;
}

@font-face{
	font-family: "Proxima Nova Semibold";
	src: url('../fonts/ProximaNova-Semibold.otf');

	font-style: normal;
	font-weight: 500;
}

@font-face{
	font-family: "Proxima Cond Semibold";
	src: url('../fonts/ProximaNovaCond-Semibold.otf');

	font-style: normal;
	font-weight: 500;
}