.sobre{

	.contet-text-about{
		margin-bottom: 32px;
	}

	.subtitle-about{
		font-size: em(20);
		line-height: 1.25;
		color: $text-dark;
		margin: 8px 0 4px;
	}

	.selo{
		background-size: contain;
		float: right;

		@media #{$small, $mobile}{
			margin: 0px auto 10px;
			float: none;
		}
	}

}

.title-about{
	font-size: 24px;
	line-height: 1.17;
	color: $red-dark-text;
	padding-bottom: 24px;
	max-width: 554px;

	&.title-about-soluctions{
		margin-bottom: 31px;
	}
}

.text-about{
	font-size: 16px;
	line-height: 1.56;
	color: $gray-transparent-text;
	padding-bottom: 32px;
}
.img-empresa{
	height: 209px;
	@media #{$mobile, $mobile-extra}{
		width: 100% !important;
    	height: 82% !important;
	}
}
.img_about{
	width: 15% !important;
}
p.text-about.float-right.text-plano_about{
	width: 85% !important;

	@media #{$tablet, $mobile}{
		width: 80% !important;
	}
	@media #{$mobile}{
		width: 100% !important;
	}
}
